@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
@keyframes bounce {
  0%   { transform: translateY(0); }
  10%   { transform: translateY(-5px); }
  50%  { transform: translateY(-15px); }
  100% { transform: translateY(0); }
}
