.wrapper {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &__content {
    flex: 1 0 auto;
  }
  &__footer {
    flex: 0 0 auto;
  }
}